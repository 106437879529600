import React from "react";
import { Link } from "gatsby";
import { GetBlogPostData } from "components/ApiServices/GetBlogPostsdata";
import { getFullDate } from "../../../common/utils";

const Blogs2 = () => {
  const getBlogPosts = GetBlogPostData();
  console.log("blogs  ",getBlogPosts);

  return (
    <section className="blog-list section-padding sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="head md-mb50">
              <h6 className="back-color">Get The Latest News</h6>
              <h3>What Our Trending News.</h3>
              <p>
                We provide company and finance service for startups and company
                business.
              </p>
              <Link to="/blog">
                <span>More Blog Posts</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1">
            {getBlogPosts.slice(0, 3).map((val, i) => (
              <div key={i} className="item wow fadeInUp" data-wow-delay=".3s">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="img valign">
                      <Link to={`/blog/${val.node.slug}`}>
                        <img style={{width: "100", height: "100",}} loading ="lazy"
                          src={val?.node?.featuredImage?.node.localFile.childImageSharp.gatsbyImageData}
                          className="img-responsive"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="cont valign">
                      <div>
                        <div className="info">
                          <Link to={`/blog/${val.node.slug}`} className="date">
                            <span>
                              <i>{getFullDate(val.node.date)}</i>
                            </span>
                          </Link>
                          <span>/</span>
                          {val?.node?.tags?.edges
                            ?.slice(0, 1)
                            .map((tag, index) => (
                              <Link
                                key={index}
                                to={`/tag/${tag.node.slug}`}
                                className="tag"
                              >
                                <span className="">
                                  {tag.node.name}
                                  {val?.node?.tags?.edges.length !== index + 1
                                    ? ", "
                                    : ""}
                                </span>
                              </Link>
                            ))}
                        </div>
                        <h5>
                          <Link to={`/blog/${val.node.slug}`}>
                            {val.node.title}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="btn-more mt-30"><a className="simple-btn" href="/blogs/">Read More</a></div> */}
        </div>
      </div>
    </section>
  );
};

export default Blogs2;
